import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';

import graphicHeart from '../images/heart.svg';
import graphicSpanner from '../images/spanner.svg';
import graphicAdmin from '../images/administrators.svg';
import graphicPrac from '../images/practitioners.svg';
import graphicPatient from '../images/patient.svg';

import candle from '../images/icon-candle.svg';
import carrot from '../images/icon-carrot.svg';
import coffee from '../images/icon-coffee.svg';
import dumbbell from '../images/icon-dumbbell.svg';
import heart from '../images/icon-heart.svg';
import medic from '../images/icon-medic.svg';
import plant from '../images/icon-plant.svg';


const IconRow = ({icon, text}) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    '> img': {
      width: '40px',
      marginRight: '28px',
    },
    '> p': {
      marginBottom: 0,
    }
  }}>
    <img src={icon} alt={`${text} icon`}/>
    <Typography type='paragraph' preset={2} disableMargin>{text}</Typography>
  </Box>
)

const HowWeWork = () => {

  return (
    <Page title='How We Work'>
      {/* Hero */}
      <Section wide>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='Phone showing the Innowell App Dashboard' src={graphicSpanner}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='hero' preset={1}>The Right Care Unlocked.</Typography>
            <Typography type='paragraph' preset={2}>
              Innowell’s research-validated digital tools and technology empower people to live happier, healthier lives with access to the mental health support they need when they need it – whether it's in the workplace or a clinic, online or offsite, day or night.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Typography type='heading' preset={2}>Real Research</Typography>
        <Typography type='paragraph' preset={2}>
          Validated through real research, over several years, across 13 locations, we’ve worked closely with the Brain and Mind Centre (BMC) at Sydney University to build a platform for clinical practices that facilitates full patient circle-of-care cooperation with access to clinically validated instruments at any time during or between sessions and empowers administrations to better triage and escalate care.
        </Typography>
        <Typography type='paragraph' preset={2}>
          That same clinical research and real science is built into Innowell’s workplace platform. Our BMC indorsed questionnaires provide accurate, real-time insights into an individual's mental health for individual users, and aggregate views of a company’s overall mental fitness across various dimensions to provide actionable, meaningful analytics and reports so you know how better to look after yourself and your people.
        </Typography>
      </Section>

      <Section>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='Heart graphic' src={graphicHeart} width='140px'/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Mental health is Health</Typography>
            <Typography type='paragraph' preset={2}>‘Mental health’ as a term is too often confused with ‘poor mental health’ as a concept. The truth is we all have mental health all the time. Like our physical health some of us struggle with specific areas and some of us have specific ailments, disabilities, or lived experiences that impact our ability to self-care for those aspects of our wellbeing.</Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Typography type='heading' preset={2}>Mental health is many things</Typography>
        <Typography type='paragraph' preset={2}>
          Our mental health isn’t just like our physical health – it’s dependent on it, along with numerous other internal and external factors. Our research-backed instruments and digital tools empower users to reflect on all key domains of mental wellness and pinpoint key focus areas to support better engagement with and self-management of all mental health domains.
        </Typography>
      </Section>

      <Section>
        <Grid container rowSpacing={4} columnSpacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <IconRow icon={candle} text='Sleep'/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <IconRow icon={heart} text='Emotional Health'/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <IconRow icon={dumbbell} text='Physical Activity'/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <IconRow icon={coffee} text='Social Connection'/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <IconRow icon={plant} text='Daily Activity'/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <IconRow icon={medic} text='Substance Use'/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <IconRow icon={carrot} text='Nutrition'/>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid container>
          <Grid item xs={6}>
            <Typography type='heading' preset={2}>
              Clinical
            </Typography>
            <Typography type='paragraph' preset={2}>
              Innowell uses clinically backed instruments and tools available at any time to patients alongside their health care professional’s guidance to best optimise care and facilitate early intervention. Our clinically validated stepped and staged model of care reduces risk and accelerates treatment for patients across all mental health domains.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography type='heading' preset={2}>
              Workplace
            </Typography>
            <Typography type='paragraph' preset={2}>
              Backed by real science, Innowell’s questionnaires provides instant insights into a user’s mental health. We recommend key focus areas and provide guided resources, and micro-activities that reshape behaviour loops and grow healthier habits. Innowell helps you grow a 'mental health first' culture that is proactive and not reactive to the mental fitness needs of your people.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      {/* Individuals/Professional/Workplaces */}
      <Section theme='primary'>
        <Typography type='heading' preset={2}>Innowell, is a digital mental health support platform that uses real research to empower: </Typography>

        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='workplaces graphic' src={graphicPatient}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Individuals</Typography>
            <Typography type='paragraph' preset={2}>To maintain their own mental health care and access the right tools and resources at the right time </Typography>
          </Grid>
        </Grid>
      </Section>
      <Section theme='primary'>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='workplaces graphic' src={graphicPrac}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Professional</Typography>
            <Typography type='paragraph' preset={2}>To deliver prioritised, efficient, and effective care </Typography>
          </Grid>
        </Grid>

        </Section>
      <Section theme='primary'>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='workplaces graphic' src={graphicAdmin}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Workplaces</Typography>
            <Typography type='paragraph' preset={2}>To predict, support, and respond to the mental fitness needs of their people. </Typography>
          </Grid>
        </Grid>
      </Section>
    </Page>
  )
};

export default HowWeWork;
